import Layer from './layer';

export default class Scene {
  managers = [];

  constructor() {
    this.body = document.getElementsByTagName('body')[0];
    this.body.style.margin = 0;
    this.body.style.padding = 0;
    this.body.style.boxSizing = 'border-box';
  }

  addLayer(name, options) {
    let canvas = document.createElement('canvas');

    canvas.id = name;
    canvas.style.position = 'absolute';
    canvas.style.top = '0';
    canvas.style.left = '0';
    canvas.style.width = '100%';
    canvas.style.height = '100%';

    for (const option in options) {
      for (const property in options[option]) {
        canvas[option][property] = options[option][property];
      }
    }

    this.body.append(canvas);

    return new Layer(name, canvas);
  }

  addManager(manager) {
    this.managers.push(manager);

    return this;
  }

  getManager(className) {
    for (let manager of this.managers) {
      if (manager instanceof className) {
        return manager;
      }
    }

    return null;
  }

  init() {
    for (let manager of this.managers) {
      manager.init(this);
    }

    return this;
  }

  render(milliseconds) {
    window.requestAnimationFrame((milliseconds) => this.render(milliseconds));

    for (let manager of this.managers) {
      manager.render(milliseconds);
    }
  }
}
