import Color from '../common/model/color';
import Experiment from './managers/experiment';
import Fps from '../common/managers/fps';
import Info from '../common/managers/info';
import Keyboard from '../common/managers/keyboard';
import Mouse from './managers/mouse';
import Navigation from '../common/managers/navigation';
import Scene from '../common/engine/scene';

(new Scene())
  .addManager(new Keyboard())
  .addManager(new Experiment({
    layer: { style: { background: 'radial-gradient(#fff, #aaa)' }},
    particle: { color: new Color(140, 85, 35) }
  }))
  .addManager(new Mouse({ color: new Color(140, 85, 35) }))
  .addManager(new Info({
    layer: {
      font: '12px monospace',
      fillStyle: 'rgb(140, 85, 35)'
    },
    help: [
      '[1-6] set fps target (x10)',
      '[c]   default/random color',
      '[v]   show/hide direction+speed vector',
      '[n]   show/hide particle number',
      '[f]   show/hide fps',
      '[p]   show/hide number of particles',
      '[h]   show/hide this help',
      '[+]   increase mouse circle size',
      '[-]   decrease mouse circle size',
      '[<]   previous experiment',
      '[>]   next experiment',
    ]
  }))
  .addManager(new Fps({ show: true }))
  .addManager(new Navigation({ experiment: 0 }))
  .init()
  .render();
