export default class Color {
  constructor(r, g, b, a = 1) {
    this.r = r;
    this.g = g;
    this.b = b;
    this.a = a;
  }

  translucent(alpha) {
    return new Color(this.r, this.g, this.b, alpha);
  }

  rgba(a = null) {
    if (a === null) {
      a = this.a;
    }

    return `rgba(${this.r}, ${this.g}, ${this.b}, ${a})`;
  }
}
