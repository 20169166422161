import Keyboard from './keyboard';
import Manager from '../engine/manager';

export default class Info extends Manager {

  /** @inheritdoc */
  constructor(options = {}) {
    super(options)

    this.texts = new Map();
    this.help = options.help;
  }

  /** @inheritdoc */
  init(scene) {
    super.init(scene);

    this.layer = scene.addLayer('info');
    this.options.help = false;

    const keyboard = this.scene.getManager(Keyboard);

    // show/hide h
    keyboard.addKey('h', () => this.options.help = !this.options.help);
  }

  /**
   * Add info text.
   *
   * @param {string} key     Text key.
   * @param {string} content Text content to show.
   */
  text(key, content) {
    this.texts.set(key, content);
  }

  /** @inheritdoc */
  render(milliseconds) {
    this.layer.clear();

    let texts = Array.from(this.texts.values()).filter(txt => txt);

    this.layer.ctx.font = this.options.layer.font || "13px Arial";
    this.layer.ctx.fillStyle = this.options.layer.fillStyle || 'rgb(140, 85, 35)';
    this.layer.ctx.fillText(texts.join(' '), 10, 15);

    if (this.options.help) {
      const width = this.layer.canvas.width - 290;
      const height = this.layer.canvas.height - (this.help.length * 20);

      for (let i = 0; i < this.help.length; i++) {
        const text = this.help[i]
        this.layer.ctx.fillText(text, width, height + (i * 20));
      }
    }
  }
}
