import Manager from '../engine/manager';

export default class Keyboard extends Manager {

  /** @inheritdoc */
  constructor(options = {}) {
    super(options);

    this.keys = {};
  }

  /** @inheritdoc */
  init(scene) {
    super.init(scene);

    window.addEventListener('keyup', event => {
      if (event.isComposing || event.code === 229) {
        return;
      }

      if (this.keys[event.key]) {
        this.keys[event.key]();
      }
    });
  }

  /**
   * Register a key.
   *
   * @param {string}   key      Associated key.
   * @param {function} callable Function to execute when key is pressed.
   */
  addKey(key, callable) {
    this.keys[key] = callable;
  }
}
