import Color from '../../common/model/color';
import Info from '../../common/managers/info';
import Keyboard from '../../common/managers/keyboard';
import Manager from '../../common/engine/manager';
import Mouse from './mouse';
import Particle from '../model/particle';
import Utils from '../../common/engine/utils';

export default class Experiment extends Manager {

  /** @inheritdoc */
  constructor(options = {}) {
    super(options);

    this.options.show = true;
    this.options.colorize = true;
    this.options.vector = false;
    this.options.number = false;
    this.particles = [];
  }

  /** @inheritdoc */
  init(scene) {
    super.init(scene);

    this.layer = scene.addLayer('experiment', this.options.layer);
    this.mouse = scene.getManager(Mouse);
    this.info = scene.getManager(Info);

    window.addEventListener('fps:under', () => this.particles.pop());
    window.addEventListener('fps:over', () => this.particles.push(this.#createParticle(this.particles.length)));

    const keyboard = this.scene.getManager(Keyboard);

    // show/hide particles
    keyboard.addKey('p', () => this.options.show = !this.options.show);
    keyboard.addKey('c', () => this.options.colorize = !this.options.colorize);
    keyboard.addKey('v', () => this.options.vector = !this.options.vector);
    keyboard.addKey('n', () => this.options.number = !this.options.number);
  }

  /** @inheritdoc */
  render() {
    this.layer.clear();

    const width = this.layer.canvas.width;
    const height = this.layer.canvas.height;

    for (let i = 0; i < this.particles.length; i++) {
      this.particles[i].update(this.layer, this.mouse, this.options);

      if (
        this.particles[i].x > width + 3 ||
        this.particles[i].y > height + 3 ||
        this.particles[i].x < -3 ||
        this.particles[i].y < -3
      ) {
        this.particles[i] = this.#createParticle(i);
      }
    }

    this.#connect();
    this.#showParticles();
  }

  /**
   * Click boom
   *
   * @param {number} x Boom x coordinate.
   * @param {number} y Boom y coordinate.
   */
  click(x, y) {
    const limit = this.mouse.outerRadius * 2;

    for (let i = 0; i < this.particles.length; i++) {
      const particle = this.particles[i];
      const dx = x - particle.x;
      const dy = y - particle.y;
      const distance = Math.hypot(dx, dy);

      if (distance > limit) {
        continue;
      }

      const velocity = Math.hypot(particle.vx, particle.vy);
      const div = (limit - distance) * 2.5 / limit + velocity;

      particle.vx = -dx / distance * div;
      particle.vy = -dy / distance * div;
    }
  }

  /**
   * Create a particle.
   *
   * @param {number} i Particle index.
   *
   * @return {Particle}
   */
  #createParticle(i) {
    const size = Utils.random(1, 6); // 6 max size
    const sizex2 = size * 2;
    const x = Utils.random(0, (innerWidth - sizex2) - sizex2) + sizex2;
    const y = Utils.random(0, (innerHeight - sizex2) - sizex2) + sizex2;
    const vx = Utils.random(0, 5) - 2.5;
    const vy = Utils.random(0, 5) - 2.5;

    const color = {
      random: new Color(
        Math.floor(Utils.random(0, 255)),
        Math.floor(Utils.random(0, 255)),
        Math.floor(Utils.random(0, 255))
      ),
      default: new Color(
        this.options.particle.color.r,
        this.options.particle.color.g,
        this.options.particle.color.b,
        size / 6
      )
    };

    return new Particle(i, x, y, vx, vy, size, color);
  }

  /**
   * Show number of particles.
   */
  #showParticles() {
    if (!this.options.show) {
      this.info.text('particles', '');
      return;
    }

    this.info.text('particles', `${this.particles.length}p`);
  }

  /**
   * Calculate particle connections.
   */
  #connect() {
    const diagonal = Math.hypot(this.layer.canvas.width, this.layer.canvas.height);
    const maxDistance = diagonal / 12;

    for (let a = 0; a < this.particles.length; a++) {
      const pA = this.particles[a];

      for (let b = a; b < this.particles.length; b++) {
          if (b == a) {
            continue;
          }

          const pB = this.particles[b];
          const distance = Math.hypot(pA.x - pB.x, pA.y - pB.y);

          if (distance < maxDistance) {
            const gradient = this.layer.ctx.createLinearGradient(pA.x, pA.y, pB.x, pB.y);
            const opacity = 1.0 - (distance / maxDistance);

            gradient.addColorStop(0, pA.color.rgba(opacity));
            gradient.addColorStop(1, pB.color.rgba(opacity));
            this.layer.ctx.strokeStyle = gradient;

            this.layer.ctx.lineWidth = 1;
            this.layer.ctx.beginPath();
            this.layer.ctx.moveTo(pA.x, pA.y);
            this.layer.ctx.lineTo(pB.x, pB.y);
            this.layer.ctx.stroke();
          }
      }

      if (this.mouse == undefined) {
        continue;
      }

      const mouseDistance = Math.hypot(pA.x - this.mouse.x, pA.y - this.mouse.y);

      if (mouseDistance <= this.mouse.outerRadius) {
        const opacityValue = (1 - (mouseDistance - this.mouse.radius) / (this.mouse.outerRadius - this.mouse.radius));

        const gradient = this.layer.ctx.createLinearGradient(pA.x, pA.y, this.mouse.x, this.mouse.y);
        gradient.addColorStop(0, pA.color.rgba(opacityValue));
        gradient.addColorStop(1, this.mouse.options.color.rgba(opacityValue));
        this.layer.ctx.strokeStyle = gradient;

        this.layer.ctx.lineWidth = 2;
        this.layer.ctx.beginPath();
        this.layer.ctx.moveTo(pA.x, pA.y);
        this.layer.ctx.lineTo(this.mouse.x, this.mouse.y);
        this.layer.ctx.stroke();
      }
    }
  }
}
