import Info from './info';
import Keyboard from './keyboard';
import Manager from '../engine/manager';

export default class Fps extends Manager {

  /** @inheritdoc */
  init(scene) {
    super.init(scene);

    this.lastRun = performance.now();
    this.info = this.scene.getManager(Info);
    this.#setLimit(30);

    const keyboard = this.scene.getManager(Keyboard);

    // show/hide fps
    keyboard.addKey('f', () => this.options.show = !this.options.show);
    keyboard.addKey('1', () => this.#setLimit(10));
    keyboard.addKey('2', () => this.#setLimit(20));
    keyboard.addKey('3', () => this.#setLimit(30));
    keyboard.addKey('4', () => this.#setLimit(40));
    keyboard.addKey('5', () => this.#setLimit(50));
    keyboard.addKey('6', () => this.#setLimit(60));
  }

  /** @inheritdoc */
  render(milliseconds) {
    const delta = (performance.now() - this.lastRun) / 1000;

    this.lastRun = performance.now();
    this.fps = 1 / delta;

    if (this.fps < this.options.limit.min) {
      window.dispatchEvent(new CustomEvent('fps:under'));
    }

    if (this.fps > this.options.limit.max) {
      window.dispatchEvent(new CustomEvent('fps:over'));
    }

    this.info.text('fps', this.options.show ? `${Math.floor(this.fps)}fps` : '');
  }

  /**
   * Set FPS limit target.
   *
   * @param {number} limit FPS limit.
   */
  #setLimit(limit) {
    this.options.limit = {};
    this.options.limit.max = limit + 5;
    this.options.limit.min = limit - 5;
  }
}
