import Keyboard from './keyboard';
import Manager from '../engine/manager';

export default class Navigation extends Manager {

  /** @inheritdoc */
  constructor(options = {}) {
    super(options);

    this.experiment = options.experiment;
    this.experiments = [];
  }

  /** @inheritdoc */
  init(scene) {
    super.init(scene);

    fetch('/experiments.json')
      .then((response) => response.json())
      .then((json) => this.experiments = json);

    const keyboard = this.scene.getManager(Keyboard);

    keyboard.addKey('<', () => this.#prev());
    keyboard.addKey('>', () => this.#next());
  }

  /**
   * Previous experiment.
   */
  #prev() {
    let experiment = this.experiment - 1;

    if (experiment < 0) {
      experiment = this.experiments.length - 1;
    }

    this.#navigateTo(experiment);
  }

  /**
   * Next experiment.
   */
  #next() {
    let experiment = this.experiment + 1;

    if (experiment === this.experiments.length) {
      experiment = 0;
    }

    this.#navigateTo(experiment);
  }

  /**
   * Navigate to.
   */
  #navigateTo(experiment) {
    if (experiment === this.experiment) {
      return;
    }

    const url = this.experiments[experiment].url;
    window.location = url;
  }
}
