export default class Layer {

  /**
   * Initialize keyboard manager.
   *
   * @param {string} name   Layer name.
   * @param {Canvas} canvas Canvas element.
   */
  constructor(name, canvas) {
    this.name = name;
    this.canvas = canvas;

    this.ctx = this.canvas.getContext('2d');

    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight;

    window.addEventListener('resize', () => {
      this.canvas.width = innerWidth;
      this.canvas.height = innerHeight;
    });
  }

  get height() {
    return this.canvas.height;
  }

  get width() {
    return this.canvas.width;
  }

  /**
   * Clear layer.
   */
  clear() {
    this.ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
  }

  pixel(point, color) {
    this.square(point, 1, color);
  }

  square(point, size, color) {
    this.ctx.fillStyle = color.rgba();
    this.ctx.fillRect(point.x, point.y, size, size);
  }

  dot(point, size, color) {
    this.ctx.beginPath();
    this.ctx.arc(point.x, point.y, size / 2, 0, Math.PI * 2, false);
    this.ctx.fillStyle = color.rgba();
    this.ctx.fill();
  }

  point(point, size, color) {
    this.ctx.beginPath();
    this.ctx.arc(point.x, point.y, size / 2, 0, Math.PI * 2, false);
    this.ctx.fillStyle = color.rgba();
    this.ctx.fill();
  }

  line(pointA, pointB, width, color) {
    this.ctx.beginPath();
    this.ctx.lineWidth = width;
    this.ctx.strokeStyle = color.rgba();
    this.ctx.moveTo(pointA.x, pointA.y);
    this.ctx.lineTo(pointB.x, pointB.y);
    this.ctx.stroke();
  }

  get buffer() {
    return this.ctx.createImageData(this.width, this.height);
  }

  set buffer(buffer) {
    this.ctx.putImageData(buffer, 0, 0);
  }
}
