import Experiment from './experiment';
import Keyboard from '../../common/managers/keyboard';
import Manager from '../../common/engine/manager';

export default class Mouse extends Manager {

  /** @inheritdoc */
  constructor(options = {}) {
    super(options);

    this.x = null;
    this.y = null;
  }

  /** @inheritdoc */
  init(scene) {
    super.init(scene);

    this.layer = scene.addLayer('mouse');
    this.#setRadiusFactor(10);

    window.addEventListener('mousemove', event => {
      this.x = event.x;
      this.y = event.y;
      this.render();
    });

    window.addEventListener('mouseout', () => {
      this.x = null;
      this.y = null;
      this.render();
    });

    // Touch events

    // window.addEventListener('touchstart', () => {
    //   this.x = event.x;
    //   this.y = event.y;
    //   this.render();
    // });

    // window.addEventListener('touchmove', () => {
    //   this.x = event.x;
    //   this.y = event.y;
    //   this.render();
    // });

    // window.addEventListener('touchend', () => {
    //   this.x = null;
    //   this.y = null;
    //   this.render();
    // });

    this.connection = scene.getManager(Experiment);

    window.addEventListener('click', event => {
      this.connection.click(event.x, event.y);
    });

    const keyboard = scene.getManager(Keyboard);

    // reduce mouse size
    keyboard.addKey('-', () => this.#setRadiusFactor(this.radiusFactor + 0.5));

    // increase mouse size
    keyboard.addKey('+', () => this.#setRadiusFactor(this.radiusFactor - 0.5));
  }

  /** @inheritdoc */
  render() {
    this.layer.clear();

    if (this.x == null || this.y == null) {
      return;
    }

    this.layer.ctx.beginPath();
    this.layer.ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
    this.layer.ctx.lineWidth = 1;
    this.layer.ctx.strokeStyle = this.options.color.rgba(0.22);
    this.layer.ctx.stroke();

    this.layer.ctx.beginPath();
    this.layer.ctx.arc(this.x, this.y, this.outerRadius, 0, Math.PI * 2, false);
    this.layer.ctx.lineWidth = 1;
    this.layer.ctx.strokeStyle = this.options.color.rgba(1);
    this.layer.ctx.stroke();
  }

  /**
   * Set radius factor.
   *
   * @param {number} factor Radius factor.
   */
  #setRadiusFactor(factor) {
    this.radiusFactor = factor;
    this.radius = Math.min(this.layer.canvas.height, this.layer.canvas.width) / this.radiusFactor;
    this.outerRadius = this.radius + 20;
  }
}
