export default class Manager {

  /**
   * Constructor.
   *
   * @param {Object} options Manager options.
   */
  constructor(options = {}) {
    this.options = options;
  }

  /**
   * Initialize manager.
   *
   * @param {Scene} scene A scene instance.
   */
  init(scene) {
    this.scene = scene;
  }

  /**
   * Function to execute on every step.
   *
   * @param {float} milliseconds Milliseconds from start.
   */
  render(milliseconds) {}
}
