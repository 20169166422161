import Point from "../model/point";

/**
 * Utils class
 */
export default class Utils {
  static distance(pointA, pointB) {
    const dx = pointA.x - pointB.x;
    const dy = pointA.y - pointB.y;

    return Math.hypot(dx, dy);
  }

  static intersection(p1, p2, p3, p4) {
    if (p1.x === p2.x && p1.y === p2.y && p4.x === p1.x) {
      return new Point(p4.x, p4.y);
    }

    if (p1.x === p2.x && p1.y === p2.y && p3.x === p1.x) {
      return new Point(p3.x, p3.y);
    }

    const x = ((p1.x * p2.y - p1.y * p2.x) * (p3.x - p4.x) - (p1.x - p2.x) * (p3.x * p4.y - p3.y * p4.x))
              / ((p1.x - p2.x) * (p3.y - p4.y) - (p1.y - p2.y) * (p3.x - p4.x));

    const y = ((p1.x * p2.y - p1.y * p2.x) * (p3.y - p4.y) - (p1.y - p2.y) * (p3.x * p4.y - p3.y * p4.x))
              / ((p1.x - p2.x) * (p3.y - p4.y) - (p1.y - p2.y) * (p3.x - p4.x));

    return new Point(x, y);
  }

  static random(min, max) {
    return Math.random() * (max - min) + min;
  }

  static randomInt(min, max) {
    return Math.round(Utils.random(min, max));
  }

  static randomSign() {
    return Math.round(Math.random()) === 0 ? -1 : 1;
  }

  static shuffle(array) {
    let currentIndex = array.length,  randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex > 0) {

      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
  }
}
