export default class Particle {
  constructor(i, x, y, vx, vy, size, color) {
    this.i = i;
    this.x = x;
    this.y = y;
    this.vx = vx;
    this.vy = vy;
    this.size = size;
    this.color = color.random;
    this.defaultColor = color.default;
    this.randomColor = color.random;
  }

  update(layer, mouse, options) {
    if (this.x > layer.canvas.width || this.x < 0) {
      this.vx = -this.vx;
    }

    if (this.y > layer.canvas.height || this.y < 0) {
      this.vy = -this.vy;
    }

    // Check collision
    const dx = mouse.x - this.x;
    const dy = mouse.y - this.y;
    const distance = Math.hypot(dx, dy);
    const ux = dx / distance;
    const uy = dy / distance;

    if (distance < mouse.radius + this.size) {
      this.vx -= ux;
      this.vy -= uy;

      if (this.vx > 2.5) { this.vx = 2.5 }
      if (this.vx < -2.5) { this.vx = -2.5 }
      if (this.vy > 2.5) { this.vy = 2.5 }
      if (this.vy < -2.5) { this.vy = -2.5 }
    }

    this.x += this.vx;
    this.y += this.vy;
    this.#draw(layer, options);
  }

  #draw(layer, options) {
    layer.ctx.beginPath();
    layer.ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2, false);

    if (options.colorize) {
      this.color = this.randomColor;
    } else {
      this.color = this.defaultColor;
    }

    layer.ctx.fillStyle = this.color.rgba();
    layer.ctx.fill();

    if (options.number) {
      layer.ctx.font = '10px monospace';
      layer.ctx.fillText(this.i + 1, this.x + this.size, this.y - this.size);
    }

    if (options.vector) {
      // Direction+speed vector
      layer.ctx.beginPath();
      layer.ctx.strokeStyle = this.color.rgba(0.5);
      layer.ctx.moveTo(this.x, this.y);
      layer.ctx.lineTo(this.x + (this.vx * 20), this.y + (this.vy * 20));
      layer.ctx.stroke();
    }
  }
}
